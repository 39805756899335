@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500&display=swap');

.App {
  text-align: center;
/*  background-color: red; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: radial-gradient(circle at 50% 50%, rgb(255, 255, 255),  rgb(255, 0, 0));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#main-header{
  font-family: 'Kanit', sans-serif;
  color: red;
}

#wrapper{
  font-family: 'Rubik', sans-serif;
  border: 1px solid white;
  box-shadow: 3px 3px grey;
  border-radius: 20px;
  margin-bottom: 30px;
}

#item{
  border-bottom-left-radius:20px;
  color: white;
}
#item2{
  border-top-right-radius:20px;
}

.nft-card {
  background-image: linear-gradient(125.83deg, rgb(30, 42, 34) 0%, skyblue);
  font-family: "SF Pro Display";
  color: white;
  box-shadow: 1px 1px 15px #000000;
}

.navbarglow {
  box-shadow: 0px 8px 15px #17b2ff;
}
.farm {
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(90,92,106,1) 0%, rgba(32,45,58,1) 81.3% );
  font-family: "SF Pro Display";
  font-weight: 300;
  color: rgb(255, 255, 255);
  box-shadow: 0 0 1px 1px #ffffff;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
    -webkit-border: 20px !important;
    -moz-border: 20px !important;
    border: 20px !important;
}
.farmcard {
color: rgb(255, 255, 255);
font-family: "SF Pro Display";
font-weight: 300;
background-image: linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
}
.n2dtitlestyle {
  color: #ffffff;
  font-family: "SF Pro Display";
  font-weight: 300;
}

.nftportal {
  /*background: blue;*/
  background: radial-gradient(circle at 50% 50%, red,  purple);
}

.stakedcard {
  background: linear-gradient(270deg, #0c0c0c, #aa1f1f);
  background-size: 400% 400%;
    -webkit-animation: AnimationStake 14s ease infinite;
    -moz-animation: AnimationStake 14s ease infinite;
    animation: AnimationStake 14s ease infinite;
    font-family: "SF Pro Display";
    color: rgb(255, 255, 255);
    box-shadow: 1px 1px 11px #FFD700;
  }
.nft-direction {
  display:inline-grid;
  grid-template-columns:repeat(4, 5fr);
  column-gap:20px; 
}
@-webkit-keyframes AnimationStake {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationStake {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationStake {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


@media only screen and (max-width: 480px) {
.nft-direction {
  display:block;
  width:50vw;
  margin-bottom:120px;
  margin:0 auto;
 }
}

@media only screen and (max-width: 770px) {
  .nft-direction {
    display:block;
    width:50vw;
    margin-bottom:120px;
    margin:0 auto;
   }
  }

  @media only screen and (max-width: 900px) {
    .nft-direction {
      display:block;
      width:50vw;
      margin-bottom:120px;
      margin:0 auto;
     }
    }